import React from 'react';
import Banner from './components/banner';

const PresentationCardPage = () => {
  return (
    <div>
      <Banner />
    </div>
  );
};

export default PresentationCardPage;
